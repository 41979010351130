<i18n>
[
    "login__unauthModal--defaultTitle",
    "login__unauthModal--defaultSubtitle",
    "login__unauthModal--cta",
    "login__unauthModal--loginCta"
]
</i18n>

<template>
    <div class="c-signup-modal u-text--center">
        <Illustration
            :illustrationSrc="illustration || flowerIllustrationImage"
            illustrationSize="104"
            class="u-spacer--3"
        />
        <h3 class="u-spacer--1 u-text--black">
            {{ header ? $t(header) : $t('login__unauthModal--defaultTitle') }}
        </h3>
        <div class="u-spacer--5 u-text--black">
            {{ subheader ? $t(subheader) : $t('login__unauthModal--defaultSubtitle') }}
        </div>
        <BaseButton
            class="u-spacer--5"
            variant="primary"
            @click="launchUserJoin"
        >
            {{ $t('login__unauthModal--cta') }}
        </BaseButton>
        <BaseMarkdown
            class="o-text--caption u-text--gray"
            :source="$t('login__unauthModal--loginCta')"
        />
    </div>
</template>

<script>
import { genericRouteNames } from '~coreModules/core/js/router-constants';

import Illustration from '~coreModules/core/components/ui/Illustration.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

import flowerIllustrationImage from '~coreImages/illustration__flowers.png';

export default {
    name: 'JoinModal',
    components: {
        BaseButton,
        Illustration,
        BaseMarkdown,
    },
    props: {
        header: {
            type: String,
            default: null,
        },
        subheader: {
            type: String,
            default: null,
        },
        illustration: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            flowerIllustrationImage,
        };
    },
    methods: {
        launchUserJoin() {
            try {
                this.$router.push({ name: genericRouteNames.join.base });
            } catch {
                window.location.href = '/rent/join';
            }
        },
    },
};
</script>

<style lang="scss">
</style>
